// src/components/SubscribeSection.js
import React from 'react';
import './SubscribeSection.css';

const SubscribeSection = () => {
  return (
    <div className="subscribe-section">
      <h2>Get Access to Premium Content</h2>
      <p>Subscribe now to unlock exclusive races, highlights, and more!</p>
      <button className="subscribe-button">Subscribe Now</button>
    </div>
  );
};

export default SubscribeSection;