// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaSearch, FaUserCircle, FaBookmark } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/">RFRO 1000KM</Link>
      </div>
      <div className="nav-links">
        <Link to="/live" className="nav-link">Live</Link>
        <Link to="/archive" className="nav-link">Archive</Link>
      </div>
      <div className="nav-right">
        <div className="nav-search">
          <input type="text" placeholder="Search..." />
          <FaSearch className="search-icon" />
        </div>
        <Link to="/watchlist" className="nav-icon"><FaBookmark /></Link>
        <Link to="/login" className="nav-icon"><FaUserCircle /></Link>
      </div>
    </nav>
  );
};

export default Navbar;