// src/components/PopularContent.js
import React from 'react';
import './PopularContent.css';

const PopularContent = () => {
  const popularVideos = [
    { title: 'Highlights', videoUrl: 'https://path-to-highlight-video', thumbnail: '/assets/placeholder.png', isPremium: false },
    { title: 'Onboards', videoUrl: 'https://path-to-onboard-video', thumbnail: '/assets/placeholder.png', isPremium: true },
    { title: 'Preview', videoUrl: 'https://path-to-preview-video', thumbnail: '/assets/placeholder.png', isPremium: false },
    { title: 'Full Replay', videoUrl: 'https://path-to-full-replay-video', thumbnail: '/assets/placeholder.png', isPremium: true },
  ];

  return (
    <div className="popular-content">
      <h2>Popular Content</h2>
      <div className="content-grid">
        {popularVideos.map((video, index) => (
          <div key={index} className="content-item">
            <img src={video.thumbnail} alt={video.title} />
            <h3>{video.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularContent;