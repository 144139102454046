// src/pages/Archive.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Archive.css';

const Archive = () => {
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const response = await axios.get('https://api.rfro-1000km.com/seasons');
        setSeasons(response.data);
      } catch (error) {
        console.error('Error fetching seasons data:', error);
      }
    };

    fetchSeasons();
  }, []);

  return (
    <div className="archive">
      <h2>Seasons</h2>
      <div className="season-grid">
        {seasons.map((season) => (
          <Link key={season.id} to={`/seasons/${season.id}`} className="season-item">
            <div className="season-overlay">
              <h3>Season {season.season_number}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Archive;