// src/pages/Home.js
import React from 'react';
import FeaturedVideo from '../components/FeaturedVideo';
import SubscribeSection from '../components/SubscribeSection';
import RecentRound from '../components/RecentRound';
import PopularContent from '../components/PopularContent';

const Home = () => {
  return (
    <div className="home">
      <FeaturedVideo />
      <SubscribeSection />
      <RecentRound />
      <PopularContent />
    </div>
  );
};

export default Home;