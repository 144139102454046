// src/components/RecentRound.js
import React from 'react';
import './RecentRound.css';

const RecentRound = () => {
  const recentRoundContent = [
    { title: 'Highlights', videoUrl: 'https://path-to-highlight-video', thumbnail: '/assets/placeholder.png', isPremium: false },
    { title: 'Onboards', videoUrl: 'https://path-to-onboard-video', thumbnail: '/assets/placeholder.png', isPremium: true },
    { title: 'Preview', videoUrl: 'https://path-to-preview-video', thumbnail: '/assets/placeholder.png', isPremium: false },
    { title: 'Full Replay', videoUrl: 'https://path-to-full-replay-video', thumbnail: '/assets/placeholder.png', isPremium: true },
  ];

  return (
    <div className="recent-round">
      <h2>Most Recent Round</h2>
      <div className="content-grid">
        {recentRoundContent.map((content, index) => (
          <div key={index} className={`content-item ${content.isPremium ? 'premium-content' : ''}`}>
            <img src={content.thumbnail} alt={content.title} />
            {content.isPremium && (
              <div className="premium-overlay">
                <span role="img" aria-label="lock">🔒</span> Premium
              </div>
            )}
            <h3>{content.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentRound;