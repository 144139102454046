// src/pages/Live.js
import React from 'react';
import './Live.css';

const Live = () => {
  return (
    <div className="live-page">
      <h1>Live Race</h1>
      <div className="video-container">
        {/* Video player for live stream */}
        <video controls src="/path/to/live-stream.mp4" />
      </div>
      <div className="race-info">
        {/* Display race details, stats, etc. */}
        <h2>Monza 1000KM</h2>
        <p>Live timing and stats here...</p>
      </div>
    </div>
  );
};

export default Live;