// src/components/FeaturedVideo.js
import React from 'react';
import './FeaturedVideo.css';

const FeaturedVideo = () => {
  return (
    <div className="featured-video">
      <video controls className="video-player" poster="path/to/featured-thumbnail.jpg">
        <source src="https://path-to-video" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay">
        <h2>Featured Race: 1000KM of Monza</h2>
      </div>
    </div>
  );
};

export default FeaturedVideo;